<template>
  <div class="home">
    <!-- <img alt="Bige logo" :src="logoBlue" /> -->
    <SearchTraining />
  </div>
</template>

<script>
// @ is an alias to /src

const SearchTraining = () => import("@/components/searchTraining2");

// import SearchTraining from "@/components/searchTraining2";
export default {
  name: "Home",
  components: {
    SearchTraining
  },
  data() {
    return {};
  }
};
</script>